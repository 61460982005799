try {
    fetch('https://securepubads.g.doubleclick.net/pagead/ppub_config')
        .then((res) => {
            if (res.status === 200) window.setNptTechAdblockerCookie?.(false)
        })
        .catch((e) => {
            window.setNptTechAdblockerCookie?.(true)
        });
} catch (e) {
    window.setNptTechAdblockerCookie?.(true)
}